import React, { useEffect, useState } from "react"

import Layout from "../../../structure/Layout/Layout"
import Seo from "../../../structure/Seo/Seo"
import Editor from "../../../element/Editor/Editor";
import CitySkyline from "../../../element/Svg/CitySkyline";

import "./styles.scss";
import { clearLSFilters } from "../../../../utils/Data";

const GuidelinesPage = () => {
  const [path, setPath] = useState("");

  useEffect(() => {
      clearLSFilters();
  }, []);

  return (
    <Layout current='a-propos' subcurrent="charte-dutilisation">
      <Seo title="Guidelines" />
      <div className="page page-grid page-cold page-guidelines" data-theme="secondary-50">
        <div className="page_inner">

          <section className="section section-main section-page section-header mini section-description">
            <div className="section_inner radius radius-small radius-bottom radius-left" data-theme="primary">
              <div className="section-title">
                <h1 className="small"><b>Charte d'utilisation</b></h1>
              </div>
            </div>
            <CitySkyline />
          </section>

          <section className="section section-secondary section-page">
            <div className="section_inner radius radius-small radius-bottom radius-right" data-theme="white">


              <Editor content={`
              <h2>Extrait du règlement intérieur de l’association Vu des Quartiers</h2>
              <div class="editor-paragraph">
                <h3>Fonctionnement de l’annuaire</h3>
                <p>
                L’annuaire d’interlocuteurs des quartiers prioritaires de la politique de la Ville, dont la 
                constitution et l’administration est le but de cette association, doit répondre aux règles
                suivantes :
                </p>
                <ul>
                  <li><div>L’accréditation des journalistes professionnels demandant accès à l’annuaire dans les conditions précisées par les statuts est gratuite.</div></li>
                  <li><div>Le référencement d’une personne dans l’annuaire ne peut faire l’objet d’une rémunération par l’association.</div></li>
                  <li><div>Les personnes référencées dans l’annuaire peuvent demander à en être retirées sans délai, par notification simple auprès de l’association.</div></li>
                  <li><div>Les données personnelles des personnes référencées doivent impérativement être protégées et ne peuvent être accessibles qu’aux journalistes professionnels accrédités auprès de l’association.</div></li>
                  <li><div>Au moment de l’inscription sur l’annuaire, les personnes référencées se voient demander dans quel cadre (zone géographique, domaine d’expertise) et sous quelles conditions (conditions horaires, jours) elles souhaitent être contactées. Ces conditions, si elles existent, sont indiquées sur la fiche personnelle des personnes référencées. Les personnes référencées peuvent demander modification de ce cadre et de ces conditions de prise de contact.</div></li>
                  <li><div>Au moment de l’inscription sur l’annuaire, les personnes référencées ont été informées du fonctionnement de l’annuaire, des statuts de l’association et du présent règlement intérieur.</div></li>
                  <li><div>Au moment de l’inscription sur l’annuaire, les personnes référencées ont également bénéficié d’une information de base concernant la règlementation en vigueur sur le droit à l’image et le droit de réponse.</div></li>
                </ul>
              </div>
              <div class="editor-paragraph">
                <h3>Bonne utilisation de l’annuaire</h3>
                <p>
                Les journalistes accrédités auprès de l’annuaire en acceptent les règles d’utilisation et s’engagent notamment :
                </p>
                <ul>
                <li>À appliquer les règles déontologiques du journalisme définies par la Charte de Munich</li>
                <li>À respecter, si elles existent, les règles spécifiques de prise de contact définies par les personnes référencées (conditions horaires, jours).</li>
                <li>À communiquer aux personnes référencées dont le témoignage a été utilisé à des fins éditoriales, la publication qui en a résulté, si elles en expriment le souhait.</li>
                </ul>
              </div>
              <div class="editor-paragraph">
                <h3>Non-respect des règles de bonne utilisation de l’annuaire</h3>
                <p>
                Le non-respect par un journaliste professionnel accrédité auprès de l’annuaire des
                règles de bonne utilisation définies à l’article 8 vaut retrait de l’accréditation sur
                décision simple du Conseil d’administration.
                </p>
                <p>
                Dans le cas d’un retrait d’accréditation motivé par le non-respect des règles
                déontologiques du journalisme, et si la production éditoriale qui a motivé ce retrait
                d’accréditation a été publiée par une rédaction de presse membre de l’association, le
                Conseil d’administration peut envisager l’exclusion au titre de l’article 3-2 du présent
                règlement, s’agissant d’une action susceptible de porter préjudice aux activités de
                l’association.
                </p>
              </div>
              <div class="editor-paragraph">
                <h3>Fonctionnement de l’association</h3>
                <ul>
                  <li>Les données et métadonnées liées à l’utilisation du site ne sont pas accessibles dans leur format brut aux membres de l’association (organes de presse ou association ayant le statut de membre) et ne sont accessibles et exploitables que par le journaliste employé de l’association et en charge de l’administration de l’annuaire.</li>
                  <li>Les données et métadonnées liées à l’utilisation du site feront l’objet d’une exploitation par l’administrateur du site sous forme de statistiques anonymisées permettant au conseil d’administration de constater l’usage qui est fait de l’outil et de mieux déterminer les axes de développement de l’annuaire (zones géographiques ou type d’interlocuteur générant le plus d’intérêt, etc.).</li>
                  <li>Le conseil d’administration a accès aux données analytiques liées à l’accréditation des journalistes, avec le nombre et le nom des organes de presse représentés, le nombre de journalistes accrédités par organe de presse.</li>
                </ul>
              </div>
            `} />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export default GuidelinesPage